/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// global.scss
@import "~swiper/scss";
@import "~swiper/scss/autoplay";
// @import "~swiper/scss/keyboard";
// @import "~swiper/scss/pagination";
@import "~swiper/scss/scrollbar";
@import "~swiper/scss/zoom";
body {
  font-size: 4vw;
  line-height : 4vw;
}

button {
  font-size: 2.5vw;
  line-height : 2.5vw;
}
.botonvolver {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 10px 5px;
  cursor: pointer;
  font-size: 40px;
}

button.days-btn {
  font-size: 2.7rem !important;
  font-weight: 800;
  color:black !important;
}

days-btn {
//  font-size: 26px;
  color:black !important;
}

.center-display-flex {
  text-align: center;
  display: flex !important;
  justify-content: center !important;
}
.centrar {
  text-align: center;
  display: block;
}
.redondo {
  display: inline;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-inline: 1rem;
}
.link {
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.5rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.texto {
  font-size: 3.5vw;
  line-height : 3.5vw;
}
.texto2 {
  font-size: 3vw;
  line-height : 3vw;
}
.mat-h1, .mat-headline, .mat-typography .mat-h1, .mat-typography .mat-headline, .mat-typography h1 {
  font-size: 3vw;
  line-height : 3vw;
}
.titulo {
  font-size: 40px;
}
.boton-dark {
  background: #000 !important;
  color: #fff;
  margin: 4px 2px;
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 10px 5px;
}
.centrarVertical {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

*:focus {
  //  outline: px;
  outline-width: 10px;
  outline-style: solid;
  outline-color: orange;
}
.barraInfo {
  padding: 10px;
}
#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;
  text-align: center;
  margin: 0;
}

#container a {
  text-decoration: none;
  text-align: center;
}

.footer-title {
  text-align: unset;
  padding-inline-start: 20px;
}
ion-modal {
  --min-width: 1200px;
  --min-height: 850px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.calendar{
  color:black !important;
//  border: 1px solid black;
  text-transform: uppercase;
  font-weight: bold;
}
ion-calendar-month{
  color:rgb(0, 0, 0) !important;
}

.ion-calendar-week{
color:#000 !important;
}
ul.transparent.week-title{
  color:#000 !important;
  font-size: 2.3vw !important;
}
button.days-btn p{
  color:#000 !important;
}
button.days-btn {
  border-radius: 60px !important;
  width: 60px !important;
  height: 60px !important;
 }
button.today {
  background-color: var(--ion-color-primary) !important;
  border-radius: 50% !important;
  margin: 4px 2px !important;
  padding-top: 1px !important;
  display: inline-block !important;
  text-align: center;
  font-size: 2.1rem !important;
  line-height: 1;
}
button.today p {
  color: white !important;
}
button.days-btn.last-month-day p {
  color: rgba(0, 0, 0, 0.25) !important;
}
button.days-btn.next-month-day p {
  color: rgba(0, 0, 0, 0.25) !important;
}
.days {
  height: 70px !important;
  margin-bottom: 0px !important;
  border: 1px solid black !important;
}

/* Structure */
.fondo {
  position: relative;
  background:rgb(243, 243, 243);
  color:black;
}

.fondotarde {
  position: relative;
  background:rgb(46, 150, 46);
  color:rgb(255, 255, 255);
}

.fondonoche {
  position: relative;
  background:rgb(0, 0, 0);
  color:white;
}
.logo {
  position: relative;
  text-align:right;
  margin-right: 5%;
 }
.temperatura {
font-size: 5vw;
//  margin-left:200px;
margin-top: 42px;
font-weight: bold;

 }
.boton {
  height:230px;
  margin:20px;
  --background-hover-opacity:40%;
  --padding-top: 0;
  --padding-start: 1em;
  --padding-end: 1em;
  --padding-bottom: 0;
  font-size: 2.8vw;
  font-weight: 500;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  display: inline-block;
  width: auto;
  vertical-align: -webkit-baseline-middle;
  font-kerning: none;
 }
.boton:hover {
  background-color: rgb(141, 141, 141); /* Green */
  color: white;
}
.boton:focus {
  background-color: rgb(141, 141, 141); /* Green */
  color: white;
}
.botones{
text-align:center;

 }
.saludo{
  margin-bottom:20px;
  font-size: 4.5vw;
  line-height : 3.6vw;
  font-weight: bold;

}
.imagen {
  display: block;
  margin-left: auto;
}

.hora {
  font-weight:bold;
  font-size: 5.5vw;
  padding-top:20px;
}
.dia{
 font-weight:bold;
 text-transform:uppercase;
 font-size: 3.5vw;
 line-height : 3.5vw;
 margin-top:12px;
 text-align:right;
}
.mes{
  font-weight:normal;
  text-transform:uppercase;
  font-size: 3.5vw;
  margin-top:20px;
  margin-bottom:20px;
  text-align:right;
}
.calendar{
font-size:23px !important;
pointer-events: none;
--color:black !important;
}

.date{
  font-weight:bold;
  text-align:left;
  font-size:8vw;
  margin-top:-10px;
  line-height: normal;
}
.calendario{
  text-align:right;
//   margin-left:25%;
//    margin-top:-80px;
--color:black !important;
}
.año{
  text-transform:uppercase;
  text-align:center;
  font-size: 3.5vw;
  font-weight:bold;
}
.mes2{
  text-transform:uppercase;
  text-align:center;
  font-size: 3vw;
}

.l-icono{
height:60%;
margin-bottom: 40px;
}
ion-calendar-week{
--color:black !important;
}
ion-calendar{
--color:black !important;
}

.ion-calendar-month{
color:black !important;
}
.card {
  width: 100px;
  height: 100px;
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card.flipped, .card.matched {
  background-color: #4CAF50;
}

button.days-btn.feriado p{
  color: #d10000 !important;
}
